import React, { useState } from 'react';
import { HfInference } from '@huggingface/inference';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,RadarChart,PolarGrid,PolarAngleAxis,PolarRadiusAxis,Radar,Legend } from 'recharts';
import { Card, Radio } from 'antd';

const TextAnalyzer = () => {
  const [inputText, setInputText] = useState('');
  const [fileContent, setFileContent] = useState('');
  const [analysisResults, setAnalysisResults] = useState(null);
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [selectedCard, setSelectedCard] = useState('1');
  const REACT_APP_AP_TOKEN = process.env.REACT_APP_AP_TOKEN || "";
  const hf = new HfInference(REACT_APP_AP_TOKEN);
  
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const content = e.target.result;
      setFileContent(content);
      setInputText(content); // 将文件内容设置到TextArea
    };
    reader.readAsText(file);
  };

  const analyzeText = async (text) => {
    setIsAnalyzing(true);
    try {
      if (!text || text.trim().length === 0) {
        throw new Error('Please enter text to analyze');
      }

      const result = await hf.textClassification({
        model: 'SamLowe/roberta-base-go_emotions',
        inputs: text,
      });

      console.log('API Response:', JSON.stringify(result, null, 2));
      console.log('Result type:', typeof result);
      console.log('Is array:', Array.isArray(result));

      if (!Array.isArray(result)) {
        throw new Error('Unexpected response format: not an array');
      }

      // 转换结果为情感维度数据
      const emotionScores = {};
      result.forEach((item, index) => {
        console.log(`Item ${index}:`, JSON.stringify(item, null, 2));
        if (item && typeof item.label === 'string' && typeof item.score === 'number') {
          emotionScores[item.label.toLowerCase()] = item.score * 100;
        } else {
          console.warn(`Skipping item ${index} due to unexpected format`);
        }
      });

      console.log('Processed emotion scores:', emotionScores);

      const transformedResult = {
        scores: emotionScores,
        label: result[0]?.label || 'unknown',
        score: result[0]?.score || 0
      };

      setAnalysisResults(transformedResult);
    } catch (error) {
      console.error('分析失败:', error);
      alert('分析失败: ' + error.message);
    }
    setIsAnalyzing(false);
  };

  const getSentimentLabel = (score) => {
    if (score > 0.5) return 'Very Positive';
    if (score > 0.1) return 'Positive';
    if (score >= -0.1) return 'Neutral';
    if (score >= -0.5) return 'Negative';
    return 'Very Negative';
  };

  const getEmoji = (emotion) => {
    if (typeof emotion !== 'string') {
      console.warn('Unexpected emotion type:', typeof emotion);
      return '🤔';
    }

    const lowerEmoji = emotion.toLowerCase();
    switch(lowerEmoji) {
      case 'admiration': return '🥰';
      case 'amusement': return '😄';
      case 'anger': return '😠';
      case 'annoyance': return '😤';
      case 'approval': return '👍';
      case 'caring': return '🤗';
      case 'confusion': return '😕';
      case 'curiosity': return '🤔';
      case 'desire': return '😍';
      case 'disappointment': return '😞';
      case 'disapproval': return '👎';
      case 'disgust': return '🤢';
      case 'embarrassment': return '😳';
      case 'excitement': return '🤩';
      case 'fear': return '😨';
      case 'gratitude': return '🙏';
      case 'grief': return '😢';
      case 'joy': return '😊';
      case 'love': return '❤️';
      case 'nervousness': return '😰';
      case 'optimism': return '🌟';
      case 'pride': return '😌';
      case 'realization': return '💡';
      case 'relief': return '😌';
      case 'remorse': return '😔';
      case 'sadness': return '😭';
      case 'surprise': return '😲';
      case 'neutral': return '😐';
      default: return '🤔';
    }
  };

  // 修改图表数据结构以适应雷达图,只显示主要情感
  const chartData = analysisResults ? Object.entries(analysisResults.scores)
    .sort((a, b) => b[1] - a[1]) // 按分数降序排序
    .slice(0, 24) // 只取前8个最显著的情感
    .map(([emotion, score]) => ({
      subject: emotion.charAt(0).toUpperCase() + emotion.slice(1), // 首字母大写
      score: score,
      fullMark: 100
    })) : [];

  const clearText = () => {
    setInputText('');
    setFileContent('');
  };

  return (
    <div className="text-analyzer">
      <h1>✨ Sentiment Analysis Dashboard ✨</h1>
      <p style={{ 
        textAlign: 'center', 
        color: '#999', 
        fontSize: '14px', 
        fontStyle: 'italic',
        marginBottom: '20px' 
      }}>
        Built with a machine learning model deployed in my backend server, it's continuously learning and improving to better understand human emotions.
      </p>
      <p style={{ textAlign: 'center', color: 'white', marginBottom: '20px' }}>
        Click the radio button in the top right corner of each note to select sample text.
      </p>
     
      <div style={{ display: 'flex', gap: '20px', marginBottom: '24px', width: '50%', margin: '0 auto' }}>
        <Card
          style={{
            width: '33%',
            backgroundColor: '#E7E3DE',
            borderRadius: '8px',
            position: 'relative'
          }}
          bodyStyle={{ padding: '16px' }}
        >
          <Radio.Group 
            value={selectedCard} 
            onChange={(e) => {
              setSelectedCard(e.target.value);
              setInputText("I wish you could have seen how it actually was; but since you'll never know, I want to tell you how it was. I saw the first ray of light that went right through the clouds. A beam of sun that lit up the earth, and two butterflies that danced in the warmth of that single ray, climbing high in the sky. And I heard the sky singing. It was beauty, Andrew. The world was beautiful.");
            }}
            style={{ 
              position: 'absolute',
              right: '16px',
              top: '16px'
            }}
          >
            <Radio value="1" style={{ transform: 'scale(1.2)' }} />
          </Radio.Group>
          <p style={{ color: '#4A4A4A', marginTop: '24px' }}>
          "I wish you could have seen how it actually was; but since you'll never know, I want to tell you how it was. I saw the first ray of light that went right through the clouds. A beam of sun that lit up the earth, and two butterflies that danced in the warmth of that single ray, climbing high in the sky. And I heard the sky singing. It was beauty, Andrew. The world was beautiful."          </p>
          <p style={{ color: '#888', fontSize: '12px', textAlign: 'right', marginTop: '12px', fontStyle: 'italic' }}>
            - Ender's Game by Orson Scott Card, 1985
          </p>
        </Card>

        <Card
          style={{
            width: '33%',
            backgroundColor: '#D8E2DC',
            borderRadius: '8px',
            position: 'relative'
          }}
          bodyStyle={{ padding: '16px' }}
        >
          <Radio.Group 
            value={selectedCard} 
            onChange={(e) => {
              setSelectedCard(e.target.value);
              setInputText("Sometimes, when one person is missing, the whole world seems depopulated. How strange it is with how much effort we attempt to preserve the memory of those we have lost – their clothes in the wardrobe, still carrying their scent; their books, with notes in the margin; their photographs, their letters, their words – while forgetting, gradually, their faces, their voices, their gestures, which were once so familiar. Time erases them slowly, like a tide washing away footprints in the sand.");
            }}
            style={{ 
              position: 'absolute',
              right: '16px',
              top: '16px'
            }}
          >
            <Radio value="2" style={{ transform: 'scale(1.2)' }} />
          </Radio.Group>
          <p style={{ color: '#4A4A4A', marginTop: '24px' }}>
            
 "Sometimes, when one person is missing, the whole world seems depopulated. How strange it is with how much effort we attempt to preserve the memory of those we have lost – their clothes in the wardrobe, still carrying their scent; their books, with notes in the margin; their photographs, their letters, their words – while forgetting, gradually, their faces, their voices, their gestures, which were once so familiar. Time erases them slowly, like a tide washing away footprints in the sand."
          </p>
          <p style={{ color: '#888', fontSize: '12px', textAlign: 'right', marginTop: '12px', fontStyle: 'italic' }}>
            - Lamartine's Memoirs, 1863
          </p>
        </Card>

        <Card
          style={{
            width: '33%',
            backgroundColor: '#E2D5D5',
            borderRadius: '8px',
            position: 'relative'
          }}
          bodyStyle={{ padding: '16px' }}
        >
          <Radio.Group 
            value={selectedCard} 
            onChange={(e) => {
              setSelectedCard(e.target.value);
              setInputText("I became insane, with long intervals of horrible sanity. During these fits of absolute unconsciousness, I drank - God only knows how often or how much. As a matter of course, my enemies referred the insanity to the drink, rather than the drink to the insanity. I had indeed nearly abandoned all hope of a permanent cure, when I found one in the death of my wife. This I can and do endure as becomes a man. It was the horrible never-ending oscillation between hope and despair which I could not longer have endured without total loss of reason.");
            }}
            style={{ 
              position: 'absolute',
              right: '16px',
              top: '16px'
            }}
          >
            <Radio value="3" style={{ transform: 'scale(1.2)' }} />
          </Radio.Group>
          <p style={{ color: '#4A4A4A', marginTop: '24px' }}>

          "I became insane, with long intervals of horrible sanity. During these fits of absolute unconsciousness, I drank - God only knows how often or how much. As a matter of course, my enemies referred the insanity to the drink, rather than the drink to the insanity. I had indeed nearly abandoned all hope of a permanent cure, when I found one in the death of my wife. This I can and do endure as becomes a man. It was the horrible never-ending oscillation between hope and despair which I could not longer have endured without total loss of reason."        </p>
          <p style={{ color: '#888', fontSize: '12px', textAlign: 'right', marginTop: '12px', fontStyle: 'italic' }}>
            - Letter from Edgar Allan Poe to George W. Eveleth, 1848
          </p>
        </Card>
      </div>

      <div className="input-section">
        <div className="file-upload">
          <h3>Upload Text File (txt)</h3>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center' }}>
            <button
              onClick={() => document.getElementById('fileInput').click()}
              style={{
                padding: '8px 16px',
                fontSize: '14px',
                backgroundColor: '#fff',
                color: '#1890ff', 
                border: '1px solid #1890ff',
                borderRadius: '6px',
                cursor: 'pointer',
                transition: 'all 0.3s'
              }}
              onMouseOver={e => {
                e.currentTarget.style.backgroundColor = '#e6f7ff';
                e.currentTarget.style.borderColor = '#69c0ff';
              }}
              onMouseOut={e => {
                e.currentTarget.style.backgroundColor = '#fff';
                e.currentTarget.style.borderColor = '#1890ff';
              }}
            >
              Choose File
            </button>
            <input 
              id="fileInput"
              type="file" 
              accept=".txt" 
              onChange={handleFileUpload}
              style={{ display: 'none' }}
            />
            {fileContent && <span>File: {document.getElementById('fileInput')?.files[0]?.name}</span>}
          </div>
        </div>

        <div className="manual-input">
          <h3>Or Enter Text Manually</h3>
          <textarea
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
            placeholder="Enter your text here..."
            style={{ width: '80%', minHeight: '200px', padding: '12px', fontSize: '16px' }}
          />
          <div style={{ display: 'flex', gap: '16px', marginTop: '20px', justifyContent: 'center' }}>
            <button
              onClick={() => analyzeText(inputText)}
              style={{
                padding: '10px 24px',
                fontSize: '16px',
                backgroundColor: '#1890ff',
                color: 'white',
                border: 'none',
                borderRadius: '6px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                boxShadow: '0 2px 0 rgba(0,0,0,0.045)'
              }}
              onMouseOver={e => e.currentTarget.style.backgroundColor = '#40a9ff'}
              onMouseOut={e => e.currentTarget.style.backgroundColor = '#1890ff'}
            >
              Analyze Text
            </button>
            <button
              onClick={clearText}
              style={{
                padding: '10px 24px', 
                fontSize: '16px',
                backgroundColor: '#fff',
                color: '#1890ff',
                border: '1px solid #1890ff',
                borderRadius: '6px',
                cursor: 'pointer',
                transition: 'all 0.3s',
                boxShadow: '0 2px 0 rgba(0,0,0,0.045)'
              }}
              onMouseOver={e => {
                e.currentTarget.style.backgroundColor = '#e6f7ff';
                e.currentTarget.style.borderColor = '#69c0ff';
              }}
              onMouseOut={e => {
                e.currentTarget.style.backgroundColor = '#fff';
                e.currentTarget.style.borderColor = '#1890ff';
              }}
            >
              Clear Text
            </button>
          </div>
        </div>
      </div>

      {isAnalyzing && <div className="loading">Analyzing... 🔄</div>}

      {analysisResults && (
        <div className="results-section">
          <h2>Analysis Results {getEmoji(analysisResults.label)}</h2>
          
          <div style={{ width: '100%', marginBottom: '40px' }}>
            <ResponsiveContainer width="100%" height={900}>
              <RadarChart data={chartData} margin={{ top: 68, right: 90, bottom: 68, left: 90 }}>
                <PolarGrid />
                <PolarAngleAxis dataKey="subject" />
                <PolarRadiusAxis domain={[0, 100]} />
                <Radar
                  name="Emotion Score (%)"
                  dataKey="score"
                  stroke="#8884d8"
                  fill="#8884d8"
                  fillOpacity={0.6}
                />
                <Tooltip formatter={(value) => value.toFixed(2) + '%'} />
                <Legend />
              </RadarChart>
            </ResponsiveContainer>
          </div>

          <div style={{ width: '100%' }}>
            <ResponsiveContainer width="100%" height={600}>
              <BarChart data={chartData} margin={{ top: 45, right: 60, bottom: 45, left: 60 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="subject" angle={45} textAnchor="start" interval={0} height={100} />
                <YAxis domain={[0, 100]} />
                <Tooltip formatter={(value) => value.toFixed(2) + '%'} />
                <Legend />
                <Bar dataKey="score" name="Emotion Score (%)" fill="#82ca9d" />
              </BarChart>
            </ResponsiveContainer>
          </div>

          <div className="sentiment-details">
            <h3>Primary Emotion: {analysisResults.label}</h3>
            <h3>Confidence: {(analysisResults.score * 100).toFixed(2)}%</h3>
          </div>
        </div>
      )}
    </div>
  );
};

export default TextAnalyzer;